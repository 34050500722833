.secondaryNavigation {
  width: var(--secondary-nav-width);
  background: var(--color-subnav-bg);
  height: 100vh;
  position: sticky;
  overflow-y: auto;
  transition: width 0.1s ease;
  top: 0;
  margin-left: 75px;
}

.profile {
  margin-left: 0;
}

.subHeadingSection {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding-left: 24px;
  width: calc(100% - 54px);
}

.subheading {
  padding: 5px 0 4px 8px;
  border-radius: 3px;
}

.subheading a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
  color: var(--color-text-sidebar);
}

.subheading a:hover {
  color: var(--color-text-primary);
  text-decoration: none;
}

.subheading.active {
  background-color: var(--color-bg-sidebar-active-item);
}

.subheading.active a {
  color: white;
}

.navigationContainer {
  padding: 94px 0 46px 30px;
}

.inactive .subheading {
  display: none;
}

.heading {
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  display: flex;
  padding: 8px 0 8px 8px;
  gap: 8px;
  align-items: center;
  width: calc(100% - 38px);
  border-radius: 3px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.heading:hover {
  background-color: var(--color-bg-primary);
}

.heading svg {
  height: 15px;
  width: 15px;
  stroke: white;
  stroke-width: 2px;
}

.heading a {
  font-size: 0.875rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: min(100vw, 1024px)) {
  .secondaryNavigation {
    display: none;
  }
}
