.navBar {
  align-items: center;
  justify-content: center;
  min-width: 100%;
  font-size: 0.85rem;
  box-sizing: border-box;
  max-width: 100vw;
  background: var(--color-bg-tertiary);
}

.login-ctas {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 16rem;
  line-height: 1.6rem;
}

.link {
  cursor: pointer;
  text-decoration: none;
}

.login {
  color: var(--color-text-secondary);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.login:hover {
  color: var(--color-text-link);
  text-decoration: none;
}

.signUp {
  border: 1px solid var(--color-text-secondary);
  letter-spacing: 2px;
  padding: 0.1rem 0.8rem;
  border-radius: 1rem;
  color: var(--color-text-secondary);
  text-transform: uppercase;
}

.signUp:hover {
  color: var(--color-button-primary-text);
  background-color: var(--color-button-primary);
  text-decoration: none;
}

.primary-bar {
  width: 100%;
  min-height: 86px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 86px auto;
  column-gap: 1.5rem;
  box-sizing: border-box;
  align-items: center;
  padding: 12px;
}

@media (max-width: 940px) {
  .primary-bar {
    padding: 0 20px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    padding: 12px;
  }

  .logo-search-area {
    justify-content: center;
    margin-bottom: 12px;
  }
}
